<div class="mp-task-info">
  <mat-icon
    class="material-icons-outlined"
    [class]="{
      'color-orange': openTask().open > 0,
      'color-gray': openTask().open === 0
    }"
  >
    {{ icon() }}
  </mat-icon>
  <span
    [class]="{
      'color-orange': openTask().open > 0,
      'color-gray': openTask().open === 0
    }"
  >
    {{ openTask().open > 0 ? openTask().open : openTask().total }}
  </span>

  @if (openTask().open > 0 && openTask().open <= openTask().total) {
    <span class="color-gray">/</span>
    <span class="color-gray">{{ openTask().total }}</span>
  }
</div>

