import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { TaskInfo } from '@mp/shared/data-access';

@Component({
  selector: 'mp-task-info',
  standalone: true,
  templateUrl: './task-info.component.html',
  styleUrl: './task-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class TaskInfoComponent {
  readonly icon: InputSignal<string> = input.required<string>();
  readonly openTask: InputSignal<TaskInfo> = input.required<TaskInfo>();
}
