import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

import { HeaderNavComponent, HeaderNavElementComponent } from '@core/ui';
import { ArticleSearchInsightsEvent } from '@mp/content-manager/artikelsuche/util';
import { InsightsEventsTrackingService } from '@mp/shared/app-insights/util';

@Component({
  selector: 'mp-aside-buttons',
  standalone: true,
  templateUrl: './aside-buttons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, MatIconModule, HeaderNavComponent, HeaderNavElementComponent],
})
export class AsideButtonsComponent {
  protected readonly ArticleSearchInsightsEvent = ArticleSearchInsightsEvent;

  constructor(private insightsEventsTrackingService: InsightsEventsTrackingService) {}

  trackFeatureAccess(event: ArticleSearchInsightsEvent): void {
    this.insightsEventsTrackingService.trackEvent(event);
  }
}
