import { ChangeDetectionStrategy, Component, InputSignal, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'mp-blocked-supplier-tag',
  standalone: true,
  templateUrl: './blocked-supplier-tag.component.html',
  styleUrl: './blocked-supplier-tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class BlockedSupplierTagComponent {
  readonly showLabel: InputSignal<boolean> = input<boolean>(true);
}
