import { permissions } from '@mpk/shared/domain';

enum JobServerPermissionsEnum {
  None = 0,

  /**
   * Use links to the job server dashboard.
   */
  ViewJobs = 1,
}

export const JobServerPermissions = permissions(JobServerPermissionsEnum, {
  featureId: 'JobServer',
});
