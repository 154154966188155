<mp-header-nav>
  <mp-header-nav-element
    [navLink]="['/', 'artikel', 'exports']"
    (activated)="trackFeatureAccess(ArticleSearchInsightsEvent.EXPORT_HISTORY_QUICK_ACCESS)"
  >
    <mat-icon>download</mat-icon>
    <span class="hidden lg:block font-bold text-base">Export Historie</span>
  </mp-header-nav-element>

  <mp-header-nav-element
    [navLink]="['/', 'artikel', 'favoriten']"
    (activated)="trackFeatureAccess(ArticleSearchInsightsEvent.FAVORITES_QUICK_ACCESS)"
  >
    <mat-icon>favorite</mat-icon>
    <span class="hidden lg:block font-bold text-base">Favoriten</span>
  </mp-header-nav-element>
</mp-header-nav>
