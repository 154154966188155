import { Pagination, PaginationMetadata } from '@core/shared/domain';

export const convertPagination = (pagination?: PaginationMetadata): Pagination | undefined => {
  if (pagination) {
    return {
      page: pagination.currentPage,
      pageSize: pagination.limit,
    };
  }

  return undefined;
};
