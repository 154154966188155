import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

import { FlyoutAnimation, FlyoutComponent, OVERLAY_DATA } from '@core/ui';

export interface FavoritesListFlyoutCreateModeReturnType {
  mode: 'create';
  listenName: string;
}

interface FavoritesListFlyoutEditModeReturnType {
  mode: 'edit';
  listenName: string;
}

interface FavoritesListFlyoutDeleteModeReturnType {
  mode: 'delete';
}

export type FavoritenlistenFlyoutReturnType =
  | FavoritesListFlyoutCreateModeReturnType
  | FavoritesListFlyoutEditModeReturnType
  | FavoritesListFlyoutDeleteModeReturnType;

export type FavoritenlistenFlyoutInputType = { mode: 'create' } | { mode: 'edit'; listenName: string };

@Component({
  selector: 'mp-favoritenlisten-flyout-component',
  templateUrl: './favoritenlisten-flyout.component.html',
  animations: FlyoutAnimation.Named('openClose'),
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FavoritenlistenFlyoutComponent implements AfterViewInit, FlyoutComponent<FavoritenlistenFlyoutReturnType> {
  @ViewChild('input') inputElement?: ElementRef<HTMLInputElement>;
  @HostBinding('class') readonly class = 'mp-favoritenlisten-flyout';
  @HostBinding('@openClose') animationState: FlyoutAnimation.State = 'open';

  readonly maxLength = 50;

  private readonly _afterClosed$: Subject<FavoritenlistenFlyoutReturnType | undefined> = new Subject();
  afterClosed$ = this._afterClosed$.pipe(delay(FlyoutAnimation.AnimationDuration));

  listenName = new UntypedFormControl(this.data.mode === 'edit' ? this.data.listenName : '', [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(this.maxLength),
  ]);

  get isEditMode(): boolean {
    return this.data.mode === 'edit';
  }

  constructor(@Inject(OVERLAY_DATA) private readonly data: FavoritenlistenFlyoutInputType) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.inputElement?.nativeElement.focus();
    }, 0);
  }

  cancel(): void {
    this.close();
  }

  saveList(): void {
    this.close({
      mode: this.isEditMode ? 'edit' : 'create',
      listenName: this.listenName.value,
    });
  }

  deleteList(): void {
    this.close({ mode: 'delete' });
  }

  close(returnValue?: FavoritenlistenFlyoutReturnType): void {
    this.animationState = 'closed';

    this._afterClosed$.next(returnValue);
    this._afterClosed$.complete();
  }
}
