export enum ArticleSearchInsightsEvent {
  EXPORT_HISTORY_QUICK_ACCESS = 'Export Historie',
  FAVORITES_QUICK_ACCESS = 'Favoriten',

  EXPERT_SEARCH_CHANGE = 'Expertensuche',
  ARTICLES_SEARCH = 'Artikelsuche Suche',

  START_PAGE_NAVIGATION = 'Artikelsuche Startseite',

  FAVORITES_LIST_BUTTON_CLICK = 'Favoritenbutton klicken',
  FAVORITES_LIST_ADD_TO_FAVORITES = 'Favoriten nutzen',
  FAVORITES_LIST_BUTTON_NEW_LIST_CLICK = 'Favoritenliste Neuanlage',
  FAVORITES_LIST_CREATION_CONFIRM = 'Favoritenliste Neuanlage erstellt',
  FAVORITES_LIST_CREATION_CANCEL = 'Favoritenliste Neuanlage abbrechen',
}
