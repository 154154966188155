import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  InputSignal,
  Output,
  Signal,
  computed,
  input,
} from '@angular/core';

import {
  IndustryCatalog,
  IndustryCatalogOpenTasks,
  UpdateIndustryCatalog,
} from '@mp/content-manager/industriekataloge/data-access';
import { ClinicCatalog, ClinicCatalogOpenTasks } from '@mp/content-manager/klinikkataloge/data-access';
import { PlatformSupplierBasic } from '@mpsm/shared/domain';

type OpenTaskIcons = 'local_shipping' | 'factory' | 'straighten';

// TODO:
// 1. Ort der Komponente im shared ergibt keinen Sinn
// 2. Warum ist ausgerechnet diese Komponente für Klinik- und Industriekataloge gemeinsam, obwohl gerade sie es ist,
//    die eigentlich wirklich den Unterschied macht? Viele andere Katalog-Komponenten sind doppelt, aber gerade diese hier
//    ist gemeinsam, obwohl bspw. ein Klinikkatalog gar keine Lieferantenzuordnung hat...

@Component({
  selector: 'mp-katalog-card',
  templateUrl: './katalog-card.component.html',
  styleUrls: ['./katalog-card.component.scss'],
})
export class KatalogCardComponent {
  @HostBinding('class') readonly class = 'mp-katalog-card';

  assignedLieferant?: PlatformSupplierBasic;

  // Wenn schon gemeinsame Komponente, wäre das hier richtig, aber das macht im Template nur Probleme
  // @Input() katalog?: IndustryCatalog | ClinicCatalog;
  readonly katalog: InputSignal<Partial<IndustryCatalog> & Partial<ClinicCatalog>> = input.required<
    Partial<IndustryCatalog> & Partial<ClinicCatalog>
  >();

  @Input() editMode = false;
  @Input() canWrite = false;
  @Input() canSave = true;
  @Input() hasDetails = true;

  @Output() readonly lieferantEdited = new EventEmitter<PlatformSupplierBasic | undefined>();

  @Output() readonly editClicked = new EventEmitter();
  @Output() readonly speichernClicked = new EventEmitter<UpdateIndustryCatalog>();
  @Output() readonly katalogClicked = new EventEmitter<IndustryCatalog | ClinicCatalog>();
  openTaskIconMap: Record<keyof IndustryCatalogOpenTasks | keyof ClinicCatalogOpenTasks, OpenTaskIcons> = {
    supplierAssignment: 'local_shipping',
    packagingUnitsAssignment: 'straighten',
    manufacturerAssignment: 'factory',
  };

  readonly articleHasPictures: Signal<boolean> = computed(() => {
    const articleWithPicturesCount: number | undefined = this.katalog().articleWithPicturesCount;

    return articleWithPicturesCount !== undefined && articleWithPicturesCount >= 0;
  });

  readonly catalogHasValidCovinRate: Signal<boolean> = computed(() => {
    const covinRate: number | undefined = this.katalog().covinRate;

    return covinRate !== undefined && covinRate >= 0;
  });

  onSpeichernClicked(supplierId?: string): void {
    if (supplierId) {
      this.speichernClicked.emit({ supplierId });
    } else if (this.assignedLieferant?.supplierId) {
      this.speichernClicked.emit({
        supplierId: this.assignedLieferant?.supplierId,
      });
    }
  }

  onEditClicked(): void {
    this.editClicked.emit();
  }

  onLieferantChanges(lieferant: PlatformSupplierBasic | undefined): void {
    this.assignedLieferant = lieferant;
    this.lieferantEdited.next(lieferant);
  }

  emitKatalogClicked(): void {
    if (this.katalog()) {
      this.katalogClicked.emit(this.katalog() as IndustryCatalog | ClinicCatalog);
    }
  }

  orginalSorting = () => 0;
}
