import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { DatePipesModule, UtilPipesModule } from '@core/shared/util';
import { CatalogCovinBadgeComponent, CatalogTypeIconComponent } from '@core/ui';
import { CatalogSupplierSelectorComponent } from '@mpsm/shared/ui';

import { TaskInfoComponent } from '../task-info/task-info.component';

import { KatalogCardComponent } from './katalog-card.component';
import { NullableDivisionPipe } from './nullable-division.pipe';

@NgModule({
  exports: [KatalogCardComponent],
  declarations: [KatalogCardComponent, NullableDivisionPipe],
  imports: [
    CommonModule,

    MatCardModule,
    MatButtonModule,
    MatIconModule,

    CatalogCovinBadgeComponent,
    CatalogTypeIconComponent,
    CatalogSupplierSelectorComponent,

    UtilPipesModule,
    DatePipesModule,
    TaskInfoComponent,
  ],
})
export class KatalogCardModule {}
